import React from 'react';
import { Route, Switch } from "wouter";
//PRIME

import HomePage from 'pages/home/HomePage';
import LoginPage from 'pages/login/LoginPage';
//import RegistroPage from 'pages/login/RegistroPage';
import MisEstudiosPage from 'pages/estudios/MisEstudiosPage';
import url from 'utils/url';
import ErrorRoute from 'components/ErrorRoute';
import UserProvider from 'providers/UserProvider';
import { AuthRoute, GuestRoute, UserNotVerifiedRoute, UserIsProvisorio } from 'components/AuthWrappers';
import MisDatosPage from 'pages/misDatos/MisDatosPage';
// import MisTurnosPage from 'pages/turnos/MisTurnosPage-old';
import MisTurnosPage from 'pages/turnos/MisTurnosPage';
import SolicitarTurnoPage from 'pages/turnos/SolicitarTurnoPage';
import VerifiedPage from 'pages/login/VerifiedPage';
import LoginProvisorioPage from 'pages/login/LoginProvisorioPage';
import LoginHpPage from 'pages/login/LoginHpPage';
import TestsPage from 'pages/tests/TestsPage';
import { ROL_PACIENTE, ROL_SECRETARIA, ROL_SUPERVISORA_SECRETARIA ,ROL_ADMINISTRADOR, ROL_MEDICO, ROL_MEDICO_ANESTESISTA, ROL_CONTROLADOR_ESTUDIOS } from 'constantes/Roles';
import HomeAdministracion from 'pages/homeAdministracion/HomeAdministracion';
import RequestResetPasswordPage from 'pages/login/RequestResetPasswordPage';
import ResetPasswordPage from 'pages/login/ResetPasswordPage';
import ConfirmarPertenezcoGrupoFamiliar from 'pages/guest/ConfirmarPertenezcoGrupoFamiliar';
// import SalaVideoconsultaPage from 'pages/telemedicina/SalaVideoconsultaPage';
 import HomeMedicoPage from 'pages/telemedicina/HomeMedicoPage';
// import ValidarCamaraMicPage from 'pages/telemedicina/ValidarCamaraMicPage';
import FormularioPreAnestesicoPage from 'pages/anestesistas/formulario/FormularioPreAnestesicoPage';
import PanelAnestesistas from 'pages/anestesistas/dashboard/PanelAnestesistas';
import HomeControlDeInforme from 'pages/panelDeContol/controlDeInformes/HomeControlDeInforme'
import PanelAdministradores from 'pages/anestesistas/dashboard/PanelAdministradores';
import BuscadorDePacientesPage from 'pages/administrador/BuscadorDePacientesPage';
import ReporteErrorForm from 'pages/reporteError/ReporteErrorForm';
import PreguntasFrecuentesPage from 'pages/PreguntasFrecuentesPage';
import SeleccionarturnoPage from 'pages/turnos/SeleccionarTurnoPage';
import ConfirmarTurnoPage from 'pages/turnos/ConfirmarTurnoPage';
import MontosPrestacionPage from 'pages/administrador/MontosPrestacionPage';
import EstadisticaPage from 'pages/administrador/estadistica/EstadisticaPage';
import PageInternaciones from 'pages/internaciones/PageInternaciones';
import VisitasMedicasPage from 'components/visitasMedicas/VisitasMedicasPage';
import PageConfiguracionInstitucionOS from 'pages/configuracion/PageConfiguracionInstitucionOS';
import PageConfiguracionMedicoOS from 'pages/configuracion/PageConfiguracionMedicoOS';
import PagePanel from 'pages/configuracion/PagePanel';
import PageConfiguracionMedicoTurnoOnline from 'pages/configuracion/PageConfiguracionMedicoTurnoOnline';
import PageConfiguracionMontosPrestacion from 'pages/configuracion/PageConfiguracionMontosPrestacion';
import PageConfiguracionMedicoPrestacion from 'pages/configuracion/PageConfiguracionMedicoPrestacion';
import RestriccionesMedicoEdadPage from 'pages/configuracion/RestriccionesMedicoEdadPage';
import RestriccionTurnosPorMes from 'pages/configuracion/RestriccionTurnosPorMes';

export default function App(){
    return (
        <UserProvider>
            <Switch>
                <Route path={url()}>
                    <GuestRoute Component={LoginPage} />
                </Route>

                <Route path={url('anestesistas/pacientes/:id_formulario?')}>
                    {params =>
                        <FormularioPreAnestesicoPage
                            params={params}
                        />
                    }
                </Route>

                <Route path={url('anestesistas/formulario-completado/:id_formulario')}>
                    {params =>
                        <AuthRoute
                            Component={FormularioPreAnestesicoPage}
                            params={params}
                            rolesId={[ROL_MEDICO_ANESTESISTA]}
                        />
                    }
                </Route>

                <Route path={url('anestesistas/profesionales')}>
                    <AuthRoute
                        Component={PanelAnestesistas}
                        rolesId={[ROL_MEDICO_ANESTESISTA]}
                        loginUrl="interno"
                    />
                </Route>

                <Route path={url('anestesistas/administradores')}>
                    <AuthRoute
                        Component={PanelAdministradores}
                        rolesId={[ROL_ADMINISTRADOR]}
                        loginUrl="interno"
                    />
                </Route>

                <Route path={url('administracion/buscarPacientes')}>
                    <AuthRoute
                        Component={BuscadorDePacientesPage}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SECRETARIA, ROL_SUPERVISORA_SECRETARIA]}
                        loginUrl="interno"
                    />
                </Route>

                <Route path={url('administracion/montosPrestacion')}>
                    <AuthRoute
                        Component={PageConfiguracionMontosPrestacion}
                        rolesId={[ROL_ADMINISTRADOR,ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('administracion/configuracion/configInstitucion')}>
                    <AuthRoute
                        Component={PageConfiguracionInstitucionOS}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('administracion/configuracion/configMedicoPrestacion')}>
                    <AuthRoute
                        Component={PageConfiguracionMedicoPrestacion}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('administracion/configuracion/configMedicosOs')}>
                    <AuthRoute
                        Component={PageConfiguracionMedicoOS}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('administracion/configuracion/configMedicoTurno')}>
                    <AuthRoute
                        Component={PageConfiguracionMedicoTurnoOnline}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('administracion/configuracion/configMedicoEdad')}>
                    <AuthRoute
                        Component={RestriccionesMedicoEdadPage}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('administracion/configuracion/restriccionTurnoPorMes')}>
                    <AuthRoute
                        Component={RestriccionTurnosPorMes}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('administracion/configuracion')}>
                    <AuthRoute
                        Component={PagePanel}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                    />
                </Route>

                <Route path={url('login-provisorio/:dni?/:claveProvisoria?')}>
                    {params =>
                        <GuestRoute
                            Component={LoginProvisorioPage}
                            params={params}
                        />
                    }
                </Route>

                <Route path={url('interno')}>
                    <GuestRoute Component={LoginHpPage} />
                </Route>

                {/*<Route path={url('registro')}>
                    <GuestRoute Component={RegistroPage} />
                </Route>*/}

                <Route path={url('inicio')} >
                    <AuthRoute Component={HomePage} rolesId={[ROL_PACIENTE]}/>
                </Route>

                <Route path={url('crearUsuarios')} >
                    <AuthRoute
                        Component={HomeAdministracion}
                        rolesId={[ROL_SECRETARIA, ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                        loginUrl="interno"
                    />
                </Route>

                <Route path={url('estadistica')} >
                    <AuthRoute
                        Component={EstadisticaPage}
                        rolesId={[ROL_ADMINISTRADOR, ROL_SUPERVISORA_SECRETARIA]}
                        loginUrl="interno"
                    />
                </Route>

                <Route path={url('inicioMedico')} >
                    <AuthRoute
                        Component={HomeMedicoPage}
                        rolesId={[ROL_MEDICO]}
                        loginUrl="interno"
                    />
                </Route>

                <Route path={url('misEstudios')} >
                    <AuthRoute Component={MisEstudiosPage} rolesId={[ROL_PACIENTE]}/>
                </Route>

                <Route path={url('misDatos/:dni?')} >
                    { params =>
                        <AuthRoute
                            Component={MisDatosPage}
                            rolesId={[ROL_PACIENTE]}
                            params={params}
                        />
                    }

                </Route>

                <Route
                    path={url('confimarGrupo/:dniPropietario?/:dni?/:tokenAceptar?/:tokenRechazar?')}
                    component={ConfirmarPertenezcoGrupoFamiliar}
                />

                <Route path={url('misTurnos')} >
                    <AuthRoute Component={MisTurnosPage} rolesId={[ROL_PACIENTE]}/>
                </Route>

                {/* <Route path={url('misTurnosPrueba')} >
                    <AuthRoute Component={MisTurnosPage} rolesId={[ROL_PACIENTE]}/>
                </Route> */}

                <Route path={url('solicitarTurno/:idPersona?/:mutual?/:centro?/:servicio?/:prestacion?')} >
                    {params =>
                        <AuthRoute
                            Component={SolicitarTurnoPage}
                            rolesId={[ROL_PACIENTE]}
                            params={params}
                        />
                    }
                </Route>

                <Route path={url('seleccionarTurno/:idPersona?/:mutual?/:centro?/:servicio?/:prestacion?/:medico?/:isConsalud?/:coseguro?')}>
                    {params =>
                        <AuthRoute
                            Component={SeleccionarturnoPage}
                            rolesId={[ROL_PACIENTE]}
                            params={params}
                        />
                    }
                </Route>

                <Route path={url('confirmarTurno/:idTurno?/:propietario?/:prestacion?/:servicio?/:centro?/:mutual?/:medico?/:isConsalud?/:coseguro?')}>
                    {params =>
                        <AuthRoute
                            Component={ConfirmarTurnoPage}
                            rolesId={[ROL_PACIENTE]}
                            params={params}
                        />
                    }
                </Route>

                <Route path={url('visitas-medicas')} >
                    <AuthRoute
                        Component={VisitasMedicasPage}
                        rolesId={[ROL_PACIENTE]}
                    />
                </Route>

                <Route path={url('verificacion')} >
                    <UserNotVerifiedRoute />
                </Route>

                <Route path={url('confirmarContrasena')} >
                    <UserIsProvisorio />
                </Route>

                <Route path={url('olvideContrasena')} component={RequestResetPasswordPage} />

                <Route path={url('api/password/reset/:token')} >
                    {params => <GuestRoute Component={ResetPasswordPage} params={params} /> }
                </Route>

                <Route path={url('verificando')} component={VerifiedPage} />

                <Route path={url('internaciones')}>
                    <AuthRoute
                        Component={PageInternaciones}
                        rolesId={[ROL_ADMINISTRADOR,ROL_PACIENTE]}
                    />
                </Route>

                <Route path={url('tests')} component={TestsPage} />

                <Route path={url('reporteError')} component={ReporteErrorForm}/>

                <Route path={url('PreguntasFrecuentes')} component={PreguntasFrecuentesPage}/>

                {/*<Route path={url('videoconsulta')} component={SalaVideoconsultaPage} />*/}

                {/*<Route path={url('validarCamaraMic')} component={ValidarCamaraMicPage} /> */}

                <Route path={url('visitas-medicas')} component={VisitasMedicasPage} />

                <Route path={url('control/informes')} >
                    <AuthRoute
                        Component={HomeControlDeInforme}
                        rolesId={[ROL_CONTROLADOR_ESTUDIOS]}
                        loginUrl="interno"
                    />

                </Route>


                <Route>
                    {() => {
                        try {
                            throw new Error("Error HTTP 404");
                        } catch (error) {                       
                            const stackLines = error.stack.split("\n");
                            const archivo = stackLines[1]?.trim() || "null";
                            const usuario = JSON.parse(localStorage.getItem("usuario")) || {
                                nombre_usuario: "NULL",
                                usuario_id: null
                            };
                            const BASE_URL = window.location.hostname === 'localhost' 
                            ? 'http://localhost/portal/public' 
                            : window.location.origin;
                        
                            fetch(`${BASE_URL}/api/registrar-excepcion`, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    "X-Requested-With": "XMLHttpRequest"
                                },
                                credentials: "include",
                                body: JSON.stringify({
                                    mensaje: error.message,
                                    linea: 312,
                                    archivo,
                                    clase: "App.jsx",
                                    url: window.location.href,
                                    nombre_usuario: usuario.nombre_usuario,
                                    usuario_id: usuario.usuario_id
                                })
                            })
                        }
                        return <ErrorRoute mensaje="404 | Página no encontrada" />;
                    }}
                </Route>

            </Switch>
        </UserProvider>
    );
}
