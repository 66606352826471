import React, { useContext } from 'react';
import { ReactComponent as BorattiIco} from 'icons/SBoratti.svg';
// import { ReactComponent as InstitutoCardiologiaIco} from 'icons/ICCCV.svg';
/*import { ReactComponent as CemebaIco} from 'icons/Cemeba.svg';
import { ReactComponent as CebacIco} from 'icons/cebac.svg';
import { ReactComponent as SanLucasIco} from 'icons/san-lucas.svg';*/
import { Link } from 'wouter';
import url from 'utils/url';
import ThemeContext from 'providers/Tema';
import { UserContext } from 'providers/UserProvider';
import { Carousel,  Dropdown } from 'react-bootstrap';
import IfRender from 'components/IfRender';
import useLogout from 'hooks/useLogout';
import { ReactComponent as FaceIco} from 'icons/social/facebook.svg';
import { ReactComponent as InstaIco} from 'icons/social/instagram.svg';
import { ReactComponent as WppIco} from 'icons/social/whatsapp.svg';
import useTraerDatosPersona from 'hooks/useTraerDatosPersona';


const AppStyle = {
    // height: '100%',
    //boxSizing: 'border-box'
};

const logoStyle = {
    height: "50px",
    width: "150px",
    filter: "drop-shadow(white 2px 1px 1px)"
};

export default function LayoutPrincipal({
    children,
    withLogos = true,
    className = '',
    withFooter = true
}){
    const { secundario } = useContext(ThemeContext);

    return (
        <div className="p-0 d-flex flex-column justify-content-between h-100" style={AppStyle}>
            <HeaderPage withLogos={withLogos}/>
            <div style={{backgroundColor: secundario}} className={`flex-grow-1 ${className}`}>
                {children}
            </div>
            <IfRender condicion={withFooter}>
                <PageFooter />
            </IfRender>
        </div>
    );
}

function HeaderPage({withLogos}){
    const { primario, fontColor } = useContext(ThemeContext);
    const user = useContext(UserContext);
    const { ultimaVez } = useTraerDatosPersona(user?.user?.dni, "dni");

    return (
        <header style={{backgroundColor: primario}} className="shadow p-2 d-flex justify-content-between">
            <IfRender condicion={withLogos}
                no={() => <h4 style={{color: fontColor}}>Bienvenido al portal del paciente</h4>}>
                <InstitucionesIcoSlider elementos={[
                    <BorattiIco style={logoStyle} />
                ]}/>
            </IfRender>

            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                <span style={{ color: fontColor, fontSize: '15px', whiteSpace: 'nowrap' }}>
                Última vez: {ultimaVez ? 
                    `${new Intl.DateTimeFormat('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(ultimaVez))} - 
                     ${new Date(ultimaVez).toLocaleTimeString([], 
                     { hour: '2-digit', minute: '2-digit', hour12: false })}` : "No disponible"}
                </span>
            <TopNavBar />
            </div>
        </header>
    );
}

function InstitucionesIcoSlider({elementos}){
    return (
        <Carousel indicators={false} controls={false} interval={2500} fade={true}>
            {
                elementos.map(
                    (elemento, index) => <Carousel.Item key={index}> { elemento } </Carousel.Item>
                )
            }
        </Carousel>
    );
}

function TopNavBar(){
    //const [location] = useLocation();
    //const locationEsLoginProvisorio = location.match(/login-provisorio/);
    const { user, logout} = useContext(UserContext);
    return (
        <nav className="d-flex align-items-center">
            <IfRender condicion={!user}
                no={() => <AuthMenu user={user} logout={logout}/> }>
                <div className="overflow-auto">
                    {/* <Link href={url('tests')} className="mx-4 text-white">
                        Pruebas
                    </Link> */}
                    {/*!locationEsLoginProvisorio ?
                    <Link href={url('registro')} className="mx-4 text-white">
                        Registro
                    </Link>
                    :
                    null
                    */}
                    {/* <Link href={url('login-provisorio')} className="mx-4 text-white">
                        Registro provisorio
                    </Link> */}
                </div>
            </IfRender>
        </nav>
    );
}

function AuthMenu({user, logout}){
    const { logout: externalLogout } = useLogout();
    const goToExternalSite = () => externalLogout(() => window.location.href = process.env.REACT_APP_CON_SALUD_URL)
    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
            <i className="pi pi-user"></i>
                {/* { `${user.datos_personales.nombre} ${user.datos_personales.apellido}` } */}
                {/* { `${user.datos_personales.nombre}` } */}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {/* <Dropdown.Item >
                    <Link href={url('tests')}>
                        Pruebas
                    </Link>
                </Dropdown.Item> */}
                <IfRender
                    condicion={user.origen.id === 1}
                    no={() => (
                        <Dropdown.Item
                            onClick={goToExternalSite}
                        >
                            {`Volver a ${user.origen.nombre}`}
                        </Dropdown.Item>
                    )}
                >
                    <Dropdown.Item>
                        <Link href={url('misDatos')}>
                            { `${user.datos_personales.nombre} ${user.datos_personales.apellido}` }
                        </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Link href={url('reporteError')} className='text-uppercase'>
                            Reportar un Problema
                        </Link>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={logout} className='text-uppercase'>
                        Cerrar sesión
                    </Dropdown.Item>
                </IfRender>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function PageFooter(){
    const {primario} = useContext(ThemeContext);
    const icoStyle = {width: '30px', height: '30px'};
    return (
        <footer className="text-center text-white" style={{backgroundColor: primario}}>
            <div className="container mt-auto">
                <section>
                    <a className="btn btn-link btn-floating m-1" href="https://www.facebook.com/SanatorioBoratti.Posadas" target="_blank" rel="noopener noreferrer">
                        <FaceIco style={icoStyle}/>
                     </a>
                    <a className="btn btn-link btn-floating m-1" href="https://www.instagram.com/sanatorioboratti/" target="_blank" rel="noopener noreferrer">
                        <InstaIco style={icoStyle}/>
                    </a>
                    <a className="btn btn-link btn-floating m-1" href="https://api.whatsapp.com/send?phone=543764293795" target="_blank" rel="noopener noreferrer">
                        <WppIco style={icoStyle}/>
                    </a>
                </section>
            </div>
            <div className="p-1 mt-auto text-center text-white">
                <label>&copy; Copyright 2020 || Sanatorio Boratti || Todos los derechos reservados</label>
            </div>
        </footer>
    );
}
