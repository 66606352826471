import React, { useContext, useState } from 'react';
import Boton from 'styledComponents/Boton.style';
import { ThemeProvider } from 'styled-components';
import FormGroup from 'components/FormGroup';
import TemaContext from 'providers/Tema';
import SkeletonForm from 'components/SkeletonForm';
import 'primeicons/primeicons.css';
import { Col, Row } from 'react-bootstrap';
import useEditarRestriccionMedicoEdad from 'hooks/configuracion/RestriccionMedicoEdad/useEditarRestriccionMedicoEdad';


const icoInfo = (
    <div className="d-inline-flex align-items-baseline" style={{ fontSize: '14px', color: '#708090', marginBottom: '15px' }}>
        <i className="pi pi-info-circle" style={{ fontSize: '14px', marginRight: '5px' }} title="Dejar los campos de edad vacíos si el profesional atiende a pediatricos y adultos"></i>
        <span>Dejar los campos de edad vacíos si el profesional atiende a pediatricos y adultos.</span>
    </div>
);

export default function ModalEditarRestriccionEdad({ restriccion }){
    const theme = useContext(TemaContext);
    const { errors, registrando, state, controller } = useEditarRestriccionMedicoEdad({ restriccion });

    const atiende = () => {
        if(restriccion.edadMaxima == null && restriccion.edadMinima == null){
            return 'TODO';
        }else{
          return (parseInt(restriccion.edadMinima) == 14 ? 'ADULTO' : parseInt(restriccion.edadMaxima) == 14 ? 'PEDIATRICOS' : 'RANGO' );
        }
    }

    return (
        <SkeletonForm
            invalid={errors}
            validando={registrando}
            handleSubmit={controller.onSubmit}
            invalidMessage={errors}
            validandoMessage ="Registrando"
            render={() => (
            <>
                <p className='text-uppercase'>Profesional: {restriccion.nombre}</p>
                <p className='text-uppercase'>Atiende: {atiende()}</p>
                {icoInfo} 
                <Row>
                    <Col>
                        <FormGroup
                            label="Edad mínima de atención"
                            type="text"
                            pattern="[0-9]*"
                            placeholder="Ingrese la edad mínima de atención"
                            name="edadMinima"
                            onChange={controller.handleOnChangeEdadMinima}
                            value={state.edadMinima}>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup
                            label="Edad máxima de atención"
                            type="text"
                            pattern="[0-9]*"
                            placeholder="Ingrese la edad máxima de atención"
                            name="edadMaxima"
                            onChange={controller.handleOnChangeEdadMaxima}
                            value={state.edadMaxima}>
                        </FormGroup>
                    </Col>
                </Row>
                <ThemeProvider theme={theme}>
                    <Boton className="btn btn-primary w-100 mt-2">
                        Actualizar
                    </Boton>
                </ThemeProvider>
            </>
            )}
        />
    );
}